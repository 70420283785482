








































import { PropType, inject, computed } from '@vue/composition-api';
import { Image } from './types';

export default {
  name: 'InstructPopup',

  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    images: {
      type: Array as PropType<Image[]>,
      required: true
    }
  },
  setup(props, ctx) {
    const readonly = inject('readonly', false);
    const showDialog = computed({
      get: () => props.isShow,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    return {
      showDialog,
      readonly
    };
  }
};
